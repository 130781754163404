import axios from "axios";
import Swal from "sweetalert2";

export let url = "https://api.onmenu.uz/uploaded_files/";

export function form_outline() {
  document.querySelectorAll(".form-outline").forEach((formOutline) => {
    new mdb.Input(formOutline).init();
  });
}

export function disabled(bool) {
  document.querySelectorAll('[type="submit"]').forEach((e) => {
    e.disabled = bool;
  });
}

const api = axios.create({
  baseURL: "https://api.onmenu.uz/",
  headers: {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  },
});

export function success(target) {
  if (target !== undefined) {
    if (typeof target == "number") {
      document.querySelectorAll("[data-dismiss='modal']")[target].click();
    } else if (typeof target == "string") {
      document.querySelector(`[${target}]`).click();
    }
  }
  return Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
  }).fire({
    icon: "success",
    title: "Выполнено",
  });
}

export function catchError(error) {
  if (error.response?.status == 401) {
    console.clear();
    localStorage.removeItem("role");
    localStorage.removeItem("access_token");
    Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    })
      .fire({
        icon: "error",
        title: "Ошибка имени пользователя или пароля",
      })
      .then(() => {
        if (window.location.pathname == "/") {
          console.log();
        } else {
          window.location.href = "/";
        }
      });
  } else if (error.message == "Network Error") {
    return Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    }).fire({
      icon: "warning",
      title: "Интернет недоступен",
    });
  } else if (error.response?.status == 422) {
    return Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    }).fire({
      icon: "warning",
      title: "Информация неполная",
    });
  } else if (error.response?.status == 404) {
    return Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    }).fire({
      icon: "error",
      title: "URL-адрес недействителен !",
    });
  } else if (error.response?.status == 400) {
    return Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    }).fire({
      icon: "error",
      title: error.response?.data.detail,
    });
  }
  console.clear();
}

// TOKEN

export function token(data) {
  let params = new URLSearchParams();

  params.append("username", data.username);
  params.append("password", data.password);

  return api.post("token", params);
}

// CATEGORY

export function categories_get(id) {
  return api.get(`categories?category_id=${id}`);
}

export function category_create(body) {
  return api.post(`create_category`, body);
}

export function category_update(body) {
  return api.put(`update_category`, body);
}

// FOOD

export function foods_get(id) {
  return api.get(`foods/${id}`);
}

export function food_create(body) {
  return api.post(`create_food`, body);
}

export function food_update(body) {
  return api.put(`update_food`, body);
}

// FOOD COMPOSITION

export function composition_create(body) {
  return api.post(`create_food_composition`, body);
}

export function composition_remove(id) {
  return api.delete(`remove_food_composition/${id}`);
}

// FOOD PRICES

export function food_price_create(body) {
  return api.post(`create_food_price`, body);
}

export function food_price_remove(id) {
  return api.delete(`remove_food_price/${id}`);
}

// FILES

export function upload_file(source, type, file) {
  return api.post(`uploade_file/${source}/${type}`, file);
}

export function file_remove(id) {
  return api.delete(`remove_file/${id}`);
}

// USER

export function this_user() {
  return api.get(`this_user`);
}

export function update_user(body) {
  return api.put(`update_user`, body);
}
